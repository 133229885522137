import React, { useEffect, useState, useContext } from "react";
import styled from 'styled-components';

import AdminLayout from "../layouts/Admin"

import UserEditTable from "../components/users/UserEditTable";
import TokenTable from "../components/users/TokenTable";
import withToasts from "../components/HOCs/withToasts"
import { UserContext } from "../contexts/UserContext";
import RoundedModal from "../components/RoundedModal";

const EditUsersContainer = styled.div`
  margin: 80px auto 0px auto;
  max-width: 1200px;
  
  .title {
    font-size: 18pt;
    font-weight: bold;
    text-align: center;
  }

  .tables {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    margin-top: 30px;
  }
`

const ModalContentWrapper = styled.div`
  line-height: 3;

  h2 {
    font-size: 18pt;
    font-weight: bold;
    text-align: center;
  }

  h3 {
    font-size: 14pt;
    font-weight: bold;
    text-align: center;
  }

  p {
    margin: 0px 20px;
    text-align: center;
  }
`

function EditUsers({toastError, toastSuccess, ...props}) {
  const { user } = useContext(UserContext);
  const [users, setUsers] = useState([]);
  const [signupTokens, setSignupTokens] = useState([]);
  const [tempPassword, setTempPassword] = useState(null);

  useEffect(() => {
    fetchUsers();
    fetchSignupTokens();
  }, []);

  async function fetchUsers() {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/user`, {
      credentials: 'include'
    });

    const users = await response.json();
    setUsers(users);
  }

  async function fetchSignupTokens() {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/signup-token?valid=true`, {
      credentials: 'include'
    });

    const tokens = await response.json();
    setSignupTokens(tokens);
  }

  async function enableUser(username) {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/user/${username}`, {
      credentials: 'include',
      method: "PUT",
      body: JSON.stringify({roles: ["user"]}),
      headers: {
        "Content-Type": "application/json"
      } 
    });

    if(username === user.username && response.ok) {
      toastSuccess("You must log out and log back in for changes to take effect");
    } else if(response.ok) {
      toastSuccess("Change successful");
    } else {
      toastError("Something went wrong");
    }

    await fetchUsers();
    return response;
  }

  async function disableUser(userToModify) {
    if(userToModify === user.username) {
      toastError(new Error("You cannot disable yourself"));
    } else {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/user/${userToModify}`, {
        credentials: 'include',
        method: "DELETE",
      });

      if(userToModify === user.username && response.ok) {
        toastSuccess("You must log out and log back in for changes to take effect");
      } else if(response.ok) {
        toastSuccess("Change successful");
      } else {
        toastError("Something went wrong");
      }

      await fetchUsers();
      return response;
    }
  }

  async function setRoleLevel(username, roleLevel) {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/user/${username}`, {
        credentials: 'include',
        method: "PUT",
        body: JSON.stringify({roleLevel}),
        headers: {
          "Content-Type": "application/json"
        } 
      });
      await fetchUsers();
      return response;
    } catch (error) {
      toastError(error);
    }
  }

  function onChangeRole(userToModify, roleLevel) {
    return async (event) => {
      if(userToModify.username === user.username) {
        toastError(new Error("You cannot edit your own role"));
      } else {
        const response = await setRoleLevel(userToModify.username, roleLevel);
        if(response.ok) {
          toastSuccess("Change successful");
        } else {
          toastError("Something went wrong");
        }
      }
    }
  }

  async function disableToken(event) {
    const token = event.target.value;
    const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/signup-token/${token}`, {
      credentials: 'include',
      method: "DELETE",
    });

    await fetchSignupTokens();
    return response;
  }

  async function resetPassword(event) {
    const userToModify = event.target.value;
    if(userToModify === user.username) {
      toastError(new Error("You cannot change your own password. This is so you do not accidentally lock all admins out of the application. Ask another admin to request a token for you."));
    } else {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/user/${userToModify}/resetPassword`, {
        credentials: 'include',
        method: "PUT"
      });

      const password = await response.text();
      setTempPassword(password);
    }
  }

  return (
    <AdminLayout>
      <EditUsersContainer>
        <h1 className="title">User Management</h1>
        <div className="tables">
          <UserEditTable 
            user={ user }
            users={ users }
            onEnableUser={ enableUser }
            onDisableUser={ disableUser }
            onChangeRole={ onChangeRole }
            onResetPassword={ resetPassword }
          />
          <TokenTable 
            tokens={ signupTokens } 
            disableToken={ disableToken }
          />
        </div>
        {
          tempPassword && 
            <RoundedModal onClickOutside={() => setTempPassword(null)} backgroundColor="white">
              <ModalContentWrapper>
                <h2>Temporary Password</h2>
                <p>This is the only time this temporary password is viewable. Please give it to the user asap.</p>
                <h3>{ tempPassword }</h3>
              </ModalContentWrapper>
            </RoundedModal>
        }
      </EditUsersContainer>
    </AdminLayout>
  )
}

export default withToasts(EditUsers);