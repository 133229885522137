import React, { useState } from 'react';
import styled from 'styled-components';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  border-radius: 0px 5px 5px 0px;
  padding: 0px 15px;
  font-size: 14pt;
  border-left: 2px solid var(--primary-border-color);
  width: 80vw;
  max-width: 400px;

  .input-pair {
    display: flex;
    flex-direction: column;
  }

  .input-pair > * {
    margin-bottom: 10px;
  }

  .submit-button {
    margin-top: 20px;
    height: 40px;
    background-color: var(--secondary-button-color);
    border: none;
    border-radius: 5px;
    color: black;
    cursor: pointer;
    font-size: 14pt;
    font-weight: bold;
    color: var(--primary-text-color);
  }

  .input-field {
    font-size: 12pt;
    height: 20px;
    border: none;
    border-bottom: 2px solid var(--primary-border-color);
  }

  .input-field:focus {
    outline: none;
    height: 20px;
    border: none;
    border-bottom: 3px solid var(--primary-border-color);
    padding-bottom: 1px;
  }
`

function LoginForm(props) {
  const [formInput, setFormInput] = useState({
    username: '',
    password: '',
  })

  function handleOnChange(propertyName) {
    return function(event) {
      event.target.reportValidity();
      setFormInput({
        ...formInput,
        [propertyName]: event.target.value.trim(),
      })
    }
  }

  function submitForm(e) {
    e.target.reportValidity();
    e.preventDefault();

    props.attemptLogin(formInput.username, formInput.password);
  }

  return (
    <Form onSubmit={submitForm}>
      <div className="input-pair">
        <label htmlFor="username" className="input-label">Username:</label>
        <input
          type="text"
          id="username"
          name="username"
          className="input-field"
          onChange={handleOnChange('username')}
          required
        ></input>
      </div>
      <div className="input-pair">
        <label htmlFor="password" className="input-label">Password:</label>
        <input
          type="password"
          id="password"
          name="password"
          onChange={handleOnChange('password')}
          className="input-field"
          required
        ></input>
      </div>
      <input
        type="submit"
        value="Login"
        className="submit-button"
      ></input>
    </Form>
  );
}

export default LoginForm;
