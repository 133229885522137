import React from "react";
import Toast from "./Toast"

export default function Toasts({ toasts, onClearToast }) {
  return toasts.map(
    (toast, index) => (
      <Toast 
        key={ toast.message + index } 
        onClick={ onClearToast(index) }
        onTimeout={ onClearToast(index) }
        removalTimeout={ 6000 }
        toast={ toast }
      />
    )
  );
}
