import React from 'react';
import styled from 'styled-components';

const BandsContainer = styled.div`
  width: 100%;
  
  .orange {
    background-color: #F7341E;
    height: 8px;
  }

  .yellow {
    background-color: #FBDC16;
    height: 8px;
  }

  .black {
    background-color: #1D2B36;
    height: 8px;
  }

  .footer-band{
    background-color: #1D2B36;
    height: 10px;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
`

function DecorativeBands() {
  return (
    <BandsContainer>
      <div className="orange"></div>
      <div className="yellow"></div>
      <div className="black"></div>
      <div className="footer-band"></div>
    </BandsContainer>
  )
}

export default DecorativeBands;