import React, { useState } from "react";
import Toasts from "../toasts/Toasts";

export default function withToasts(WrappedComponent) {
  return function WithToasts(props) {
    const [toasts, setToasts] = useState([]);

    function toastError(error) {
      const newToast = {
        message: error.message,
        type: "error"
      }

      setToasts([...toasts, newToast]);
    }

    function toastSuccess(message) {
      const newToast = {
        message,
        type: "success"
      }

      setToasts([...toasts, newToast]);
    }

    function handleClearToast(index) {
      return () => setToasts(toasts.filter((_, errorIndex) => index !== errorIndex));
    }

    try {
      return (
        <>
          <Toasts toasts={ toasts } onClearToast={ handleClearToast } removalTimeout={ 6000 } />
          <WrappedComponent {...props} toastError={ toastError } toastSuccess={ toastSuccess } />
        </>
      )
    } catch(err) {
      console.log(err);
    }
  }
}