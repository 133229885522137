function findMatches(protocol, input) {
  const numsAndLetters = /[^A-Za-z0-9]+/;
  const title = protocol.title.toLowerCase().replace(numsAndLetters, '');
  const id = protocol.protocolId.toLowerCase().replace(numsAndLetters, '');
  const userInput = input.toLowerCase().replace(numsAndLetters, '');
  const titleOrIdMatch = title.includes(userInput) || id.includes(userInput);

  return titleOrIdMatch;
}

export default findMatches;