import React from 'react';
import styled from 'styled-components';


const CategoryCard = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-left: 7px solid ${ props => props.color };
  box-shadow: 5px 5px 10px var(--light-shadow-color);
  width: 132px;
  height: 122px;
  margin: 0 20px 25px 20px;

  background-color: var(--primary-background-color);
  color: var(--primary-text-color);

  .category-icon {
    height: 40px;
  }

  .category-name {
    line-height: 20px;
    padding: 0 5px;
    font-size: 18px;
    word-break: break-all;
  }
`

function Category(props) {
  return (
    <CategoryCard color={props.category.color} onClick={() => props.onClick && props.onClick(props.category)}>
      {props.icon && <img src={props.icon} alt={`${props.category.title} icon`} className="category-icon"/>}
      <div className="category-name">{props.category.title}</div>
    </CategoryCard>
  );
}

export default Category;

