import React, { useEffect, useState } from "react";
import styled from "styled-components";

import AdminLayout from "../layouts/Admin";

import CategoryEditor from "../components/category/CategoryEditor";
import Category from "../components/category/Category";
import CategoryDBHistory from "../components/category/CategoryDBHistory";

import withToasts from "../components/HOCs/withToasts";

const PageContainer = styled.div`
  display: flex;

  .category-editor {
    border: 1px solid black;
    display: flex;
    flex-direction: column;
  }
`;

const CategoriesContainer = styled.div`
  border: 1px solid black;
  padding: 20px;
  width: 30%;
  height: 900px;
  overflow-y: auto;

  .options {
    margin-left: 20px;
    margin-bottom: 25px;
  }

  .options > * {
    margin-bottom: 15px;
  }

  .category-entry {
    display: flex;
    flex-direction: row;
  }

  .category-entry > p {
    color: #646464;
    font-weight: bold;
  }
`;

const SelectedCategoryContainer = styled.div`
  border: 1px solid black;
  padding: 20px;
  width: 60%;
  
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;

  > * {
    margin: 0 10px;
    flex-grow: 1;
  }
`;

const EditCategoriesDirections = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > * {
    margin-bottom: 30px;
  }

  h1 {
    margin-top: 20px;
    font-size: 20pt;
    font-weight: bold;
  }

  p {
    font-size: 14pt;
  }
`

function AdminCategory({category, onClick}) {
  return (
    <Category 
      category={category} 
      icon={category.icon && `${process.env.REACT_APP_S3_BUCKET_URL}/${category.icon}`}
      onClick={onClick}
    />
  );
}

function EditCategories({ toastError, toastSuccess }) {
  const [categories, setCategories] = useState([]);
  const [displayedCategories, setDisplayedCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isNewCategory, setIsNewCategory] = useState(false);
  const [showDeleted, setShowDeleted] = useState(false);
  const [hasBeenEdited, setHasBeenEdited] = useState(false);

  async function getCategories() {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/category`, {credentials: 'include'});
    const downloadedCategories = await response.json();
    const sortedCategories = downloadedCategories.sort((a, b) => {
      return a.slot - b.slot;
    });

    setCategories(sortedCategories);
  }

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    const categoriesToDisplay = categories.filter(category => !category.deleted || showDeleted);
    setDisplayedCategories(categoriesToDisplay);
  }, [showDeleted, categories]);

  useEffect(() => {
    function confirmLeave(event) {
      if(hasBeenEdited) {
        event.returnValue = "You have unsaved changes, are you sure you want to leave?"
      }
    }

    window.addEventListener('beforeunload', confirmLeave);

    return function() {
      window.removeEventListener('beforeunload', confirmLeave);
    }
  }, [hasBeenEdited])

  function clearCategorySelection() {
    setHasBeenEdited(false);
    setSelectedCategory(null);
    setIsNewCategory(false);
  }

  function selectCategory(category) {
    if(!hasBeenEdited || window.confirm("Do you wish to change selected protocol and lose all current changes?")) {
      setHasBeenEdited(false);
      setSelectedCategory(category);
      setIsNewCategory(false);
    }
  }

  async function onEdit() {
    if(!hasBeenEdited) setHasBeenEdited(true);
  }

  async function unDeleteProtocol() {
    try{
      const response = await fetch(`${process.env.REACT_APP_API_URL}/category/${selectedCategory._id}`, {
        method: "PUT",
        credentials: 'include',
        body: JSON.stringify({deleted: false}),
        headers: {
          "Content-Type": "application/json"
        }
      });
      handleAPIResponse(response);
    } catch(error) {
      toastError(error);
    }
  }

  async function deleteProtocol() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/category/${selectedCategory._id}`, {
        method: "DELETE",
        credentials: 'include'
      });
      handleAPIResponse(response);
    } catch(error) {
      toastError(error);
    }
  }

  async function onDelete() {
    if(window.confirm("Are you sure you want to delete this category?")) {
      if(selectedCategory.deleted) {
        await unDeleteProtocol();
      } else {
        await deleteProtocol();
      }
      setHasBeenEdited(false);
    }
  }

  async function handleCategoryUpdate(content) {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/category/${selectedCategory._id}`, {
        method: "PUT",
        credentials: 'include',
        body: JSON.stringify(content),
        headers: {
          "Content-Type": "application/json"
        }
      });
      handleAPIResponse(response);
    } catch(error) {
      toastError(error);
    }
  }

  async function handleCategoryCreate(content) {
    try{
      const response = await fetch(`${process.env.REACT_APP_API_URL}/category`, {
        method: "POST",
        credentials: 'include',
        body: JSON.stringify(content),
        headers: {
          "Content-Type": "application/json"
        }
      });

      handleAPIResponse(response);
    } catch(error) {
      toastError(error);
    }
  }

  function handleAPIResponse(response) {
    if(response.status > 299) {
      // TODO: Make helper with good error codes
      toastError(new Error(`This operation was unable to be completed because of the following reason: ${response.statusText}`));
    } else {
      toastSuccess("Category has been updated");
      clearCategorySelection();
    }

    getCategories();
  }

  async function onSubmit(content) {
    if(window.confirm("Are you sure you want to save these changes?")) {

      // If there is an _id, we know it is not a new category and can update
      if(selectedCategory._id) {
        await handleCategoryUpdate(content);
      } else {
        await handleCategoryCreate(content);
      }

      setHasBeenEdited(false);
    }
  }

  function onNewCategory() {
    setSelectedCategory({
      title: "New Category", 
      icon: "", 
      linkType: "protocol_list", 
      linkData: "", 
      slot: "",
      color: ""
    });

    setIsNewCategory(true);
  }

  function handleOnApplyHistory(historicalItem) {
    onSubmit(historicalItem);
  }

  return (
    <AdminLayout>
      <PageContainer>
        <CategoriesContainer>
          <div className="options">
            <button onClick={onNewCategory}>New Category</button>
            <div>
              <label htmlFor="show-deleted">Show Deleted: </label>
              <input type="checkbox" name="show-deleted" onChange={() => setShowDeleted(!showDeleted)}></input>
            </div>
          </div>
          <ul>
            {displayedCategories.map((category, index) => {
              return (
                <div className="category-entry" key={category._id}>
                  <p>{category.slot}</p>
                  <AdminCategory 
                    category={category} 
                    onClick={selectCategory}
                  />
                </div>
              );
            })}
          </ul>
        </CategoriesContainer>

        <SelectedCategoryContainer>
          {
            !selectedCategory && 
            <EditCategoriesDirections>
              <h1>Directions</h1>
              <p>Create a new category with the button on the top left.</p>
              <p>Show previously deleted categories by clicking the checkbox.</p>
            </EditCategoriesDirections>
          }
          {
            selectedCategory && 
            <CategoryEditor 
              categoryList={categories}
              category={selectedCategory}
              onEdit={onEdit}
              onDelete={onDelete}
              onSubmit={onSubmit}
              isNewCategory={isNewCategory}
            />
          }

          {
            selectedCategory &&
            !isNewCategory && 
            <CategoryDBHistory 
              category={ selectedCategory } 
              onApplyHistory={handleOnApplyHistory}
            />
          }
        </SelectedCategoryContainer>
      </PageContainer>
    </AdminLayout>
  );
}

export default withToasts(EditCategories);
