import React from "react";
import styled from "styled-components";

const MDEditorContainer = styled.div`
  display: flex;

  textarea {
    width: 100%;
  }

  .colors {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-left: 20px;

    p {
      padding-bottom: 20px;
    }
  }
`;

export default function MDProtocolEditor(props) {
  function onColorChange(event, index) {
    const newColorBars = [...props.protocol.colorBars];
    newColorBars[index] = event.target.value;

    const fakeEvent = {
      target: {
        name: "colorBars",
        value: newColorBars
      }
    }

    props.onChange(fakeEvent);
  }

  return (
    <MDEditorContainer>
      <textarea 
        className="protocol-body" 
        name="content" 
        value={ props.protocol.content } 
        onChange={ props.onChange } 
      />
      <div className="colors">
        <p>Colors</p>
        {
          props.protocol.colorBars.map((color, index) => (
            <input 
              key={index}
              type="color" 
              value={color}
              onChange={(event) => onColorChange(event, index)}
            ></input>
          ))
        }
      </div>
    </MDEditorContainer>
  )
}

