import React, { useState } from 'react';
import styled from 'styled-components';

import TapIcon from "./TapIcon";
import TapMenuModal from "./TapMenuModal";

const TapMenuElements = styled.div`
  z-index: 1000;

  position: fixed;
  bottom: 30px;
  right: 20px;
`

function TapMenu() {
  let [menuTapped, menuTap] = useState(false);

  function closeModal() {
    menuTap(false);
  }

  return (
    <TapMenuElements className="main">
      <TapIcon 
        menuTapped={menuTapped}
        menuTap={() => menuTap(menuTapped = !menuTapped)}
      />

      { menuTapped && 
        <TapMenuModal 
          closeModal={closeModal} 
          toggleModal={() => menuTap(menuTapped = !menuTapped)}
        /> 
      }
    </TapMenuElements>
  );
}

export default TapMenu;
