import React, { useState } from 'react';
import Modal from "../Modal";

import { dbActions } from "../../db";

import MainMenu from "./MainMenu";
import SettingsMenu from "./SettingsMenu";


function TapMenuModal(props) {
  const [selectedScreen, setSelectedScreen] = useState("main");

  function back() {
    window.history.back();
  }

  function forward() {
    window.history.forward();
  }

  async function resetApp() {
    await dbActions.clearDB();
    await dbActions.updateDB();
  }

  function setScreen(screenName) {
    return () => setSelectedScreen(screenName);
  }

  const displayScreen = (selectedScreen, props) => {
    const newProps = {
      toggleModal: props.toggleModal, 
      closeModal: props.closeModal,
      setScreen,
      back, 
      forward, 
      resetApp
    }

    switch(selectedScreen) {
      case "main":
        return <MainMenu {...newProps}></MainMenu>;
      case "settings":
        return <SettingsMenu {...newProps}></SettingsMenu>;
      default:
        return <MainMenu {...newProps}></MainMenu>;
    }
  }

  return (
    <Modal onClickOutside={props.toggleModal}>
      { displayScreen(selectedScreen, props) }
    </Modal>
  );
}

export default TapMenuModal;
