import React from "react";
import DirectionStyleWrapper from "../UI/DirectionStyleWrapper";

function ProtocolEditorDirections() {
  return (
    <DirectionStyleWrapper>
      <h1>Protocol Editor</h1>
      <p>This is where you can edit the contents of protocols</p>
      <br></br>

      <h2>Title and ID</h2>
      <p>The title can be whatever you would like, shorter titles are easier to view for the end-user.</p>
      <p>The letter in the ID is how you categorize types of protocols and view them under a category with the matching "prefix". The number is the order they will show up inside a category.</p>
      <br></br>
      <br></br>

      <h2>Reset</h2>
      <p>This allows you to reset your changes back to what was shown before making edits.</p>
      <br></br>
      <br></br>

      <h2>Delete</h2>
      <p>This will delete a protocol, you can only delete a protocol if it has no references to it</p>
      <br></br>
      <br></br>

      <h2>Image Protocol Guide</h2>
      <p>Changing the "type" to "Image" will allow you to upload .pngs, .jpgs, and vectors. If the image is large, please be patient with uploading after clicking "save"</p>
      <br></br>
      <br></br>

      <h2>Adding images to protocols</h2>
      <p>Create a new image protocol, and link it inline, this will allow a user to click the link and be taken to that image, going back will take them back to the page they clicked from</p>
      <br></br>
      <br></br>

      <h2>PDF Protocol Guide</h2>
      <p>Changing the "type" to "pdf" will allow you to upload pdfs.</p>
      <br></br>
      <br></br>

      <h2>MD Guide</h2>
      <br></br>

      <h3>Paragraphs</h3>
      <p>
        To put items in their own paragraph, you should put an extra newline between them.
      </p>
      <br></br>
      <div>
        <p>Example: </p>
        <pre className="code">
          {`
Paragraph 1

Paragraph2
This line here is still part of "Paragraph2", If I want to start another paragraph

I must have a space between, like this.
          `}
        </pre>
      </div>
      <br></br>

      <h3>Accordion Menus</h3>
      <p>
        Accordion menus allow you to hide content until the user is ready to view it. If you want content always expanded you must put the content at the top, before you start using accordion menus. 
        You can start the next accordion menu by simply adding another "##" on a new line. Note that if you add content and it becomes cut-off, 
        close and re-open the accordion to view the updated content.
      </p>
      <br></br>
      <div>
        <p>Usage: </p>
        <pre className="code">
          {`
## Title Of Menu
Any content of any type
- including bullet points
          `}
        </pre>
      </div>
      <br></br>
      <br></br>
      <br></br>

      <h3>Bullet points</h3>
      <p>
        Bullet points allow easy reading of lists, they can also be nested. Nesting is accomplished by adding two spaces before a bullet point.
      </p>
      <br></br>
      <p>Singular bullet point: <span className="code">{`- bullet point`}</span></p>
      <br></br>
      <div>
        <p>Multiple bullet points: </p>
        <pre className="code">
          {`
- bullet One
- bullet two
          `}
        </pre>
      </div>
      <div>
      <br></br>
        <p>Nested bullet points: </p>
        <pre className="code">
          {`
- bullet One
  - This has two spaces before the '-'
  - This also does, and will display as a sub-bullet point of "Bullet One"
          `}
        </pre>
      </div>
      <br></br>
      <br></br>
      <br></br>

      <h3>Checkboxes</h3>
      <p>
        Checkboxes allow for a way to keep track of things that have been done, additionally a timestamp is attached at the time of checking. Nesting works the same as bullet points.
      </p>
      <br></br>
      <p>Singular checkbox: <span className="code">{`- [ ] Checkbox`}</span></p>
      <p>It's important to note, bullet points have a space after the <span className="code">{`-`}</span> and between the <span className="code">{`[ ]`}</span></p>
      <br></br>
      <div>
        <p>Multiple checkboxes: </p>
        <pre className="code">
          {`
- [ ] Check One
- [ ] Check two
          `}
        </pre>
      </div>
      <div>
      <br></br>
        <p>Nested checkboxes: </p>
        <pre className="code">
          {`
- [ ] Check One
  - [ ] This has two spaces before the '-'
  - [ ] This also does, and will display as a sub-checkbox of "Check One"
          `}
        </pre>
      </div>
      <br></br>
      <br></br>
      <br></br>

      <h3>Protocol Reference</h3>
      <p>
        References to other protocols become links to the specific protocol linked, these have the special ability of updating automatically when the ID of the protocol changes. 
        Use these when you want to link to related protocols.
      </p>
      <br></br>
      <p>Usage: <span className="code">{`<e-10>`}</span> where 'e' is the protocol prefix, and '10' is the ID.</p>
      <br></br>
      <br></br>
      <br></br>

      <h3>Colored Sections</h3>
      <p>
        This will allow you to add colors to the side of the text, to notate who has permissions to use these protocols. Whenever a "---" is encountered, 
        you must select the color on the right side of the protocol editor. The first three default to yellow, blue, and red. If you want to add more colors, or edit them, you can click the
        color button, and it will allow you to change to whichever color you would like.
      </p>
      <div>
      <br></br>
        <p>Example: </p>
        <pre className="code">
          {`
## Title Of Accordion
content that will have first color
---
content that will have second color
---
content that will have third color
          `}
        </pre>
      </div>
      <br></br>
      <br></br>
      <br></br>
    </DirectionStyleWrapper>
  )
}

export default ProtocolEditorDirections;
