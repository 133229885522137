import { createGlobalStyle, css } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  html {
    --primary-color: black;
    --secondary-color: white;
    --accent-color: #676767;
    --primary-border-color: #c4c4c4;
    --modal-background-color: #2b2b2beb;
    --secondary-background-color: grey;
    --light-shadow-color: #cccccc;
    --secondary-text-color: #444;

    --primary-background-color: var(--secondary-color);
    --primary-shadow-color: var(--accent-color);
    --primary-text-color: var(--primary-color);
    --primary-button-color: var(--accent-color);
    --primary-button-border-color: var(--primary-border-color);
    --secondary-button-color: var(--primary-border-color);
    --touch-menu-button-color: var(--primary-background-color);
    --modal-background-text-color: var(--secondary-color);

    background-color: var(--primary-background-color);
  }

  body {
    background-color: var(--primary-background-color);
  }
  
  ${(props) => props.theme === "dark" && css`
    html {
      --primary-color: #ededed;
      --secondary-color: #171717;
      --accent-color: black;
      --primary-border-color: grey;
      --modal-background-color: #000000eb;
      --secondary-background-color: grey;
      --light-shadow-color: #262626;
      --secondary-text-color: #cfcfcf;
      --touch-menu-button-color: var(--secondary-text-color);
      --modal-background-text-color: var(--primary-color);
      --primary-button-color: var(--light-shadow-color);
      color: var(--primary-text-color);
    }
    
    html a {
      color: #218cff;
    }
  `}
`;

export default GlobalStyles;