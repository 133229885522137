import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 2em;
    padding: 20px;
  }

  button {
    width: 200px;
    height: 40px;
    margin: 10px;
  }
`;

function NotFound(props) {
  return (
    <Wrapper>
      <h1>{props.message || "Not Found"}</h1>

      <div>
        <button onClick={() => window.history.back()}>Go Back</button>
        <Link to="/">
          <button>Go Home</button>
        </Link>
      </div>
    </Wrapper>
  )
}

export default NotFound;