import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";

const colorMap = {
  error: {
    background: "#FFA9A9",
    border: 'red',
  },
  success: {
    background: "#BCBBFF",
    border: "blue"
  }
}

const ToastWrapper = styled.div`
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
`;

const ToastMessage = styled.div`
  padding: 0 10px;
  width: 50%;
  z-index: 9999;
  height: 100px;
  border: 2px solid ${props => colorMap[props.type].border};
  background-color: ${props => colorMap[props.type].background};

  .content {
    width: 100%;
    height: 100%;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media(max-width: 750px) {
    width: 70%;
  }

  @media(max-width: 450px) {
    width: 85%;
  }
`;

const toastRoot = document.getElementById('toast-root');

export default function Toast(props) {
  const { toast, onTimeout, removalTimeout = 6000 } = props;
  useEffect(() => {
    if ( onTimeout ) {
      const timeoutToRemove = setTimeout(() => onTimeout(), removalTimeout);

      return () => {clearTimeout(timeoutToRemove)}
    }
  }, [onTimeout, removalTimeout])

  const toastPopup = (
    <ToastWrapper>
      <ToastMessage type={ toast.type } onClick={props.onClick}>
        <div className="content">
          <p>
            { toast.message }
          </p>
        </div>
      </ToastMessage>
    </ToastWrapper>
  )

  return createPortal(
    toastPopup,
    toastRoot
  );
}