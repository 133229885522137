import React from 'react';
import styled from 'styled-components';

const HeaderContainer = styled.header`
  padding: 20px 16px 20px;
  border-bottom: 1.5px solid var(--primary-border-color);
  margin-bottom: 25px;
  ${props => props.color ? `border-left: 12px solid ${props.color};` : ''}

  display: flex;
  align-items: center;

  color: var(--primary-text-color);

  h1, h2 {
    font-weight: bold;
    font-size: 26px;
    line-height: 28px;
  }

  h1 {
    margin-right: 20px;
  }

  h2.id {
    text-transform: uppercase;
    justify-self: flex-end;
    margin-left: auto;
    border-left: 1px solid var(--primary-color);
    padding-left: 20px;
  }

  img {
    margin-right: 14px;
    width: 45px;
  }
`

function Header(props) {
  return (
    <HeaderContainer color={props.color}>
      { props.img && <img className="image" src={ props.img } alt={ props.title }></img> }
      <h1>{props.title}</h1>
      { props.id && <h2 className="id">{props.id}</h2> }
    </HeaderContainer>
  );
}

export default Header;
