import React from "react";

import TableStyling from "./TableStyling";

export default function TokenTable(props) {
  const {tokens, disableToken} = props;

  return (
    <TableStyling>
      <thead>
        <tr>
          <th>
            token
          </th>
          <th>
            expiration
          </th>
          <th>
          </th>
        </tr>
      </thead>
      <tbody>
        {tokens.map(token => (
          <tr key={token.token}>
            <td>
              <p>{token.token}</p>
            </td>
            <td>
              <p>{token.expiration}</p>
            </td>
            <td>
              <button 
                onClick={ disableToken } 
                value={token.token}
              >Disable</button>
            </td>
          </tr>
        ))}
      </tbody>
    </TableStyling>
  )
}