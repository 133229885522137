import React, { useState } from "react";

export const SettingsContext = React.createContext();

const defaultSettings = {
  adminMode: false,
  darkMode: false
}

function syncLocalStorage({settings}) {
  localStorage.setItem('settings', JSON.stringify({...defaultSettings, ...settings}));
}

export function withSettingsContextProvider(Component) {
  return function SettingsContextProvider(props) {
    const [settings, setSettings] = useState(JSON.parse(localStorage.getItem('settings')) || {...defaultSettings});

    function setAdminMode(value = false) {
      setSettings({ ...settings, adminMode: value });
      syncLocalStorage({settings: {...settings, adminMode: value}});
    }

    function setDarkMode(value = false) {
      setSettings({ ...settings, darkMode: value });
      syncLocalStorage({settings: {...settings, darkMode: value}});
    }

    // TODO Use Proper reducer/action pattern
    const settingsActions = {
      SET_ADMIN_MODE: setAdminMode,
      SET_DARK_MODE: setDarkMode
    };

    return (
      <SettingsContext.Provider value={{ settings, settingsActions }}>
        <Component {...props} />
      </SettingsContext.Provider>
    );
  };
}
