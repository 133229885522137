import styled from 'styled-components';

const UserTable = styled.div`
  border: 1px solid var(--primary-border-color);
  margin-bottom: 80px;

  tr:nth-child(even) {
    background-color: var(--secondary-background-color);
  }

  th {
    font-weight: bold;
    text-align: left;
    background-color: var(--primary-border-color);
    color: var(--primary-text-color);
  }

  th, td {
    padding: 10px;
  }

  button {
    margin-top: 10px;
    height: 40px;
    background-color: var(--secondary-button-color);
    border: 1px solid var(--primary-button-color);
    border-radius: 5px;
    color: var(--primary-text-color);
    cursor: pointer;
    font-size: 12pt;
    font-weight: bold;
  }

  .truncate {
    display: block;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export default UserTable;