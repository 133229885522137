import React from "react";
import styled from "styled-components";

const InputPairContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;

  margin-bottom: 40px;
  
  > label {
    width: 15%;
    float: left;
  }
`

function ProtocolListCategory(props) {
  const { category } = props;
  return (
    <InputPairContainer>
      <label htmlFor="protocol_prefix">
        Id Prefix:
      </label>
      <input 
        name="protocol_prefix" 
        value={category.linkData} 
        onChange={props.onProtocolPrefixChange}
        pattern="^[A-z]+$"
        title="Must be only letters A-z"
        required
      />
    </InputPairContainer>
  )
}

function LinkCategory(props) {
  const { category } = props;
  return (
    <InputPairContainer>
      <label htmlFor="link_to">
        Link To:
      </label>
      <input 
        name="link_to" 
        value={category.linkData} 
        onChange={props.onLinkChange}
        required
      />
    </InputPairContainer>
  )
}

export default function CategoryLinkTypeEditorAdapter(props) {
  const { category } = props;

  function handleOnChange(propertyName) {
    return function(event) {
      props.onChange({[propertyName]: event.target.value});
    }
  }

  switch(category.linkType) {
    case "protocol_list":
      return (
        <ProtocolListCategory 
          category={category} 
          onProtocolPrefixChange={handleOnChange('linkData')}
        />
      );
    case "link":
      return (
        <LinkCategory 
          category={category}
          onLinkChange={handleOnChange('linkData')}
        />
      )
    case "category_list":
    default:
      return null;
  }

}