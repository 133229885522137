import React, { useState, useRef } from "react";
import styled from "styled-components";

import RoundedModal from "../RoundedModal";
import withToasts from "../HOCs/withToasts";

const IconSelectorWrapper = styled.div`
  img {
    width: 60px;
    height: 60px;
    margin-right: 15px;
  }

  .icon-elements {
    display: flex;
    flex-direction: row;
  }

  .icon-elements > button {
    margin: auto 0;
    height: 28px;
    margin-right: 15px;
  }
`;

const IconPopup = styled.div`
  position: absolute;
`;

const IconList = styled.div`
  overflow-y: auto;
  width: 380px;
  height: 300px;
  border: 1px solid black;
  background-color: white;

  button {
    width: 80px;
    height: 80px;
    margin: 20px;
  }

  button img {
    width: 60px;
    height: 60px;
  }
`;

const IconModalContentWrapper = styled.div`
  padding: 0 20px;
`

function IconSelector({toastError, toastSuccess, ...props}) {
  const [icons, setIcons] = useState([]);
  const [selectingIcon, setSelectingIcon] = useState(false);
  const [uploadingIcon, setUploadingIcon] = useState(false);

  const fileRef = useRef(null);
  const fileNameRef = useRef(null);

  async function getIcons() {
    try{
      const response = await fetch(`${process.env.REACT_APP_API_URL}/icon`, {credentials: 'include'});
      const downloadedIconPaths = await response.json();
      setIcons(downloadedIconPaths);
    } catch(error) {
      toastError(error);
    }
  }

  async function openIconSelector(event) {
    event.preventDefault();
    setSelectingIcon(true);
    await getIcons();
  }

  function closeIconSelector(event) {
    event && event.preventDefault();
    setSelectingIcon(false);
  }

  function selectIcon() {
    return (event) => {
      event.preventDefault();
      closeIconSelector();
      const iconSelectEvent = {...event, target: event.currentTarget};
      props.onChange(iconSelectEvent);
    }
  }

  async function uploadNewIcon() {
    const iconFile = fileRef.current.files[0];
    const displayName = fileNameRef.current.value;

    const formData = new FormData();
    formData.append('icon', iconFile);
    formData.append('displayName', displayName)

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/icon`, {
        method: "POST",
        credentials: 'include',
        body: formData,
      });

      handleUploadResponse(response);
    } catch(error) {
      toastError(error)
    }
  }

  async function handleUploadResponse(response) {
    if(response.status > 299) {
      const message = await response.text();
      toastError(new Error(message));
    } else {
      await getIcons();
      toastSuccess("Icon Uploaded");
    }

    setUploadingIcon(false);
  }

  return (
    <IconSelectorWrapper>
      <div className="icon-elements">
        <img 
          src={`${process.env.REACT_APP_S3_BUCKET_URL}/${props.value}`} 
          alt={ props.name }
        />

        { 
          !selectingIcon ?
          <button type="button" onClick={ openIconSelector }>Select Icon</button> :
          <button type="button" onClick={ closeIconSelector }>Cancel</button> 
        }

        <button type="button" onClick={ selectIcon("") }>Clear Icon</button>
      </div>

      { 
        selectingIcon &&
        <IconPopup> 
          <IconList>
            {icons.map(icon => (
              <button 
                key={ icon._id }
                name={ props.name } 
                onClick={ selectIcon(icon.s3Path) } 
                value={ icon.s3Path }
              >
                <img 
                  src={`${process.env.REACT_APP_S3_BUCKET_URL}/${icon.s3Path}`}
                  alt={ icon.name }
                />
                { icon.displayName }
              </button>
            ))}
          </IconList>

          <button type="button" onClick={() => setUploadingIcon(true)}>Upload New Icon</button>

          {
            uploadingIcon &&
           
            <RoundedModal onClickOutside={ () => setUploadingIcon(false) }>
              <IconModalContentWrapper>
                <input type="text" placeholder="name" ref={fileNameRef} />
                <input type="file" ref={fileRef} />
                <button type="button" onClick={ uploadNewIcon }>Submit</button>
              </IconModalContentWrapper>
            </RoundedModal>

          }
        </IconPopup> 
      }

    </IconSelectorWrapper>
  );
}

export default withToasts(IconSelector);
