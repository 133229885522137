import React from "react";

import MDProtocolEditor from "./MDProtocolEditor";
import FileProtocolEditor from "./FileProtocolEditor";

const pdfAccept = "application/pdf"
const imageAccept = "image/svg+xml, image/webp, image/gif, image/jpeg, image/png"

export default function ProtocolContentEditor(props) {
  const contentType = props.protocol.contentType || "markdown";

  switch(contentType) {
    case "markdown":
      return (
        <MDProtocolEditor 
          onChange={props.onChange}
          protocol={props.protocol} 
        />
      )
    case "image":
      return (
        <FileProtocolEditor 
          onFileLoad={props.onFileLoad} 
          protocol={props.protocol} 
          accept={imageAccept} 
        />
      )
    case "pdf":
      return (
        <FileProtocolEditor 
          onFileLoad={props.onFileLoad} 
          protocol={props.protocol} 
          accept={pdfAccept}
        />
      )
    default:
      return <p>Content Type: { contentType }</p>
  }
}