import React, { useState, useEffect } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import styled from "styled-components";

import Category from "./Category";

const Categories = styled.div`
  margin-top: 25px;

  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
`

function LinkCategory({category, icon}) {
  return (
    <Link to={`${category.linkData}`} key={category._id} className="override-default">
      <Category category={category} icon={icon}/>
    </Link>
  );
}

function ProtocolListCategory({category, icon}) {
  return (
    <Link to={`/category/${category.title}`} key={category._id} className="override-default">
      <Category category={category} icon={icon}/>
    </Link>
  );
}

function CategoryListCategory({category, icon}) {
  const { url } = useRouteMatch();

  // If the URL is a `/` we need to not return it so the route doesn't start with //
  const linkTo = url === '/' ? '' : url;
  return (
    <Link to={`${linkTo}/category/${category.title}`} key={category._id} className="override-default">
      <Category category={category} icon={icon}/>
    </Link>
  );
}

function CategoryAdapter({category}) {
  const [categoryIcon, setCategoryIcon] = useState(null);

  useEffect(() => {
    if(!category.icon) return;

    if(category.icon.arrayBuffer) {
      const iconBlob = new Blob([category.icon.arrayBuffer], {type: category.icon.type});
      const iconObjUrl = URL.createObjectURL(iconBlob);
      setCategoryIcon(iconObjUrl);
    } else {
      const iconUrl = `${process.env.REACT_APP_S3_BUCKET_URL}/${category.icon}`;
      setCategoryIcon(iconUrl);
    }
  }, [category.icon])

  const categoryProps = {category: category, icon: categoryIcon};

  switch(category.linkType) {
    case "protocol_list":
      return <ProtocolListCategory {...categoryProps} />
    case "link":
      return <LinkCategory {...categoryProps} />
    case "category_list":
      return <CategoryListCategory {...categoryProps} />
    default:
      return null;
  }
}

function CategoryList({categories}) {
  return (
    <ul>
      <Categories>
        { categories.map(category => 
          <CategoryAdapter category={category} key={category._id} />
        ) }
      </Categories>
    </ul>
  );
}

export default CategoryList;
