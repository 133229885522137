import React from "react";
import { createPortal } from "react-dom";
import styled from 'styled-components';

const ModalContainer = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: var(--modal-background-color);
`
const modalRoot = document.getElementById('modal-root');

function Modal(props) {
  const modal = (
    <ModalContainer  
      onClick={(event) => {
        // Check if clicked div is the same as the div we're attaching the onClick
        if (event.target === event.currentTarget) props.onClickOutside(event)
      }}
    >
      {props.children}
    </ModalContainer>
  );

  return createPortal(
    modal,
    modalRoot
  );
}

export default Modal;
