import React, { useState } from "react";
import styled from "styled-components";

import HistoryItem from "./HistoryItem";
import HistoryModal from "./HistoryModal";
import Header from "../Header";
import AdminDirectionButton from "../UI/AdminDirectionButton";
import TableStyling from "../users/TableStyling";
import HistoryAdminDirections from "./HistoryAdminDirections";

const HistoryWrapper = styled.div`
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    margin-bottom: 20px;
  }

  .directions {
    align-self: flex-end;
    margin-right: 20px;
  }
`;

const HistoryTable = styled.table`
  > tbody ${HistoryItem} tr td {
    padding-right: 10px;
    padding-left: 10px;
  }
`;

export default function HistoryList(props) {
  const [selectedIndex, setSelectedIndex] = useState(null);
  if(!props.history) return null;
  const { history } = props;

  function showHistory(index) {
    return () => {
      setSelectedIndex(index);
    }
  }

  function closeModal() {
    setSelectedIndex(null);
  }

  function handleOnApply() {
    props.onApplyHistory(history[selectedIndex].historicalData);
  }

  return (
    <>
     { selectedIndex !== null && 
        <HistoryModal onClose={closeModal} onApply={handleOnApply}> 
          <Header title={history[selectedIndex].historicalData.title} id={history[selectedIndex].historicalData.protocolId} />
          { props.preview(history[selectedIndex].historicalData) }
        </HistoryModal>
     }
      <HistoryWrapper>
        <h3>History</h3>
        <TableStyling>
          <HistoryTable>
            <tbody>
              {history.map((historicalItem, index) => 
                <HistoryItem 
                  key={historicalItem._id}
                  isCreationEntry={index === history.length - 1}
                  history={historicalItem} 
                  onPreview={showHistory(index)}
                />
              )}
            </tbody>
          </HistoryTable>
        </TableStyling>

        <div className="directions">
          <AdminDirectionButton><HistoryAdminDirections /></AdminDirectionButton>
        </div>
      </HistoryWrapper>
    </>
  );
}