import React, { useContext, useEffect, useState } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import styled from "styled-components";

import { withUserContextProvider, UserContext } from "./contexts/UserContext";
import { withSettingsContextProvider, SettingsContext } from "./contexts/SettingsContext";

import { dbEvents } from "./db";

import GlobalStyles from './GlobalStyles';
import Index from "./pages/Index";
import CategoryPage from "./pages/CategoryPage";
import ProtocolPage from "./pages/ProtocolPage";
import EditProtocolPage from "./pages/EditProtocolPage";
import AuthPage from "./pages/AuthPage";
import EditCategoriesPage from "./pages/EditCategoriesPage";
import SearchPage from "./pages/SearchPage";
import EditUsersPage from "./pages/EditUsersPage";
import ChangePasswordPage from "./pages/ChangePasswordPage";

import NotFound from "./components/NotFound";
import TapMenu from "./components/tap-menu/TapMenu";
import LoadingSpinner from "./components/UI/LoadingSpinner";

const Loading = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  text-align: center;
  margin-top: 30vh;

  h1 {
    padding: 20px;
    font-size: 1.3em;
    font-weight: bold;
  }
  
  p {
    margin-bottom: 10px;
  }
`;

function App() {
  const history = useHistory();
  const [forceUpdate, setForceUpdate] = useState(false);
  const [dbInitialized, setDbInitialized] = useState(false);
  const { user } = useContext(UserContext);
  const loggedIn = user && !user.loggedOut;
  const loggedInAdmin = loggedIn && user.isAdmin;
  if(user && user.forceReset) history.push("/changepassword");

  const { settings } = useContext(SettingsContext);

  useEffect(() => {
    let alreadyUpdated = false;
    let stopListeningCb = () => {}

    function dbUpdateHandler() {
      stopListeningCb = history.listen(() => {
        if(!alreadyUpdated) {
          alreadyUpdated = true;
          setForceUpdate(!forceUpdate);
        }
      });
    }

    function initializedHandler() {
      if(!alreadyUpdated) {
        setDbInitialized(true)
        alreadyUpdated = true;
      }
    }

    dbEvents.on("initialized", initializedHandler);
    dbEvents.on("updated", dbUpdateHandler);

    return () =>  {
      dbEvents.off("initialized", initializedHandler);
      dbEvents.off("updated", dbUpdateHandler);
      stopListeningCb();
    }
  }, [forceUpdate, dbInitialized, history]);

  const Spinner = (
    <>
      <GlobalStyles theme={settings.darkMode && "dark"}/>
      <Loading>
        <div>
          <h1>Initializing and downloading content</h1>
          <p>Please wait</p>
        </div>

        <LoadingSpinner />
      </Loading>
    </>
  )

  if(loggedIn && !dbInitialized) {
    return Spinner;
  }

  return (
    <>
      <GlobalStyles theme={settings.darkMode && "dark"}/>

      {/* // This key is a hack to force the entire component tree to update */}
      <div key={forceUpdate}>
        { loggedIn ? <TapMenu /> : null }
        <Switch>
          <Route
            path="/"
            exact
            render={() => loggedIn ? <Index /> : <Redirect to="/authenticate" /> }
          />
          <Route
            path="/editCategories"
            exact
            render={ () => loggedInAdmin ? <EditCategoriesPage /> : <Redirect to="/" /> }
          />
          <Route
            path="/editUsers"
            exact
            render={ () => loggedInAdmin ? <EditUsersPage /> : <Redirect to="/" /> }
          />
          <Route 
            path="/authenticate"
            render={() => loggedIn ? <Redirect to="/" /> : <AuthPage /> }
          />
          <Route
            path="/newProtocol"
            render={ () => loggedInAdmin ? <EditProtocolPage isNew={true} /> : <Redirect to="/" /> }
          />
          <Route
            path="/category/:categoryName"
            render ={ () => loggedIn ? <CategoryPage /> : <Redirect to="/authenticate" /> }
          />
          <Route
            path="/protocol/:protocolId"
            exact
            render={ () => loggedIn ? <ProtocolPage /> : <Redirect to="/authenticate" /> }
          />
          <Route
            path="/protocol/:protocolId/edit" 
            exact
            render={ (props) => loggedInAdmin ? <EditProtocolPage /> : <Redirect to={`/protocol/${props.match.params.protocolId}`} /> }
          />
          <Route
            path="/search"
            render={ () => loggedIn ? <SearchPage /> : <Redirect to="/authenticate" /> }
          />
          <Route
            path="/changepassword"
            render={ () => user && user.forceReset ? <ChangePasswordPage /> : <Redirect to="/authenticate" /> }
          />
          <Route path="*">
            <NotFound message="Page not found" />
          </Route>
        </Switch>
      </div>
    </>
  );
}

export default withUserContextProvider(withSettingsContextProvider(App));
