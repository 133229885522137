import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import AdminDirectionButton from "../UI/AdminDirectionButton";
import DocumentsReferencingDirections from "./DocumentsReferencingDirections";

const ReferencedWrapper = styled.div`
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    margin-bottom: 20px;
  }


  .documents-referencing {
    display: flex;
    flex-direction: column;
  }

  .direction-button {
    align-self: flex-end;
    padding-right: 20px;
    margin-top: 40px;
  }
`;

export default function ReferencedInList(props) {
  return (
    <ReferencedWrapper>
      <h3>Documents Referencing</h3>
      <div className="documents-referencing">
        {props.referencingDocuments && props.referencingDocuments.map(document => {
          return (
            <Link key={document.protocolId} to={`/protocol/${document.protocolId}/edit`}>{`${document.protocolId}: ${document.title}`}</Link>
          )
        })}
      </div>
      <div className="direction-button">
        <AdminDirectionButton><DocumentsReferencingDirections /></AdminDirectionButton>
      </div>
    </ReferencedWrapper>
  )
}
