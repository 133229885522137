import Dexie from "dexie";
import { EventEmitter } from "events"

import isIOS from "./util/isIOS";

export const db = new Dexie("hyperProto");

db.version(1).stores({
  categories: "_id, title, linkType, linkData, slot, parent, color", // Icon is a blob
  protocols: "_id, protocolId, title, content, contentType, referencedDocuments", // content can be a file
});

db.open();

async function fetchAllCategories() {
  await db.categories.clear();
  const categoryResponse = await fetch(`${process.env.REACT_APP_API_URL}/category?download=true`, {
    credentials: 'include',
  });
  const categories = await categoryResponse.json();
  const categoriesWithIcons = await fetchIconsForCategories(categories);

  return await db.categories.bulkAdd(categoriesWithIcons);
}

async function fetchFilesForProtocols(protocols) {
  return Promise.all(protocols.map(async protocol => {
    if(protocol.contentType === 'markdown') {
      return protocol;
    } else {
      const res = await fetch(`${process.env.REACT_APP_S3_BUCKET_URL}/${protocol.content}`);
      const fileBlob = await res.blob();

      if(isIOS()) {
        const content = {
          type: fileBlob.type,
          arrayBuffer: await fetch(URL.createObjectURL(fileBlob)).then(res => res.arrayBuffer())
        }

        return {...protocol, content };
      } else {
        const content = {
          type: fileBlob.type,
          arrayBuffer: await fileBlob.arrayBuffer()
        }

        return {...protocol, content};
      }
    }
  }));
}

async function fetchIconsForCategories(categories) {
  return Promise.all(categories.map(async category => {
    if(category.icon) {
      const res = await fetch(`${process.env.REACT_APP_S3_BUCKET_URL}/${category.icon}`);
      const iconBlob = await res.blob();

      if(isIOS()) {
        const icon = {
          type: iconBlob.type,
          arrayBuffer: await fetch(URL.createObjectURL(iconBlob)).then(res => res.arrayBuffer())
        }

        return {...category, icon };
      } else {
        const icon = {
          type: iconBlob.type,
          arrayBuffer: await iconBlob.arrayBuffer()
        }

        return {...category, icon };
      }

    } else {
      return category;
    }
  }));
}

async function fetchAllProtocols() {
  await db.protocols.clear();
  const protocolResponse = await fetch(`${process.env.REACT_APP_API_URL}/protocol?download=true`, {
    credentials: 'include',
  });

  const protocolReader = protocolResponse.body.getReader();
  const textDecoder = new TextDecoder("utf-8");
  const bulkAddPromises = [];

  let buffer = '';
  while(true) {
    const { done, value } = await protocolReader.read();
    if(done) break;
    buffer += textDecoder.decode(value);

    // If the last character is a newline, we can assume that everything before is a valid JSON object,
    // therefore we can find the last newline '\n', and slice the buffer to that point and parse it
    // as an array of objects
    const lastNewlineIndex = buffer.lastIndexOf('\n');
    const rawDownloadedProtocols = buffer.substring(0, lastNewlineIndex); // segregate the completed objects 
    buffer = buffer.substring(lastNewlineIndex); // Remove completed objects from buffer, leaving partial data

    // Replacing newlines with commas, then surrounding in [] for a valid JSON object turned into an array
    const protocols = JSON.parse(`[${rawDownloadedProtocols.trim().split('\n').join(',')}]`);
    const completeProtocols = await fetchFilesForProtocols(protocols);

    // Add to the indexedDB
    bulkAddPromises.push(db.protocols.bulkAdd(completeProtocols));
  }

  return await Promise.all(bulkAddPromises);
}

function setDBDate() {
  localStorage.setItem('lastUpdateCheck', new Date());
}

async function clearDB() {
  localStorage.removeItem("lastUpdateCheck");
  return Promise.all([
    db.categories.clear(),
    db.protocols.clear(),
  ]);
}

async function downloadAll() {
  await Promise.all([
    fetchAllCategories(),
    fetchAllProtocols(),
  ]);

  setDBDate();
}

async function updateProtocols(protocols) {
  const completeProtocols = await fetchFilesForProtocols(protocols);
  const protocolIdsToDelete = protocols.filter(protocol => protocol.deleted).map(protocol => protocol.protocolId);

  await db.protocols.bulkPut(completeProtocols);
  return await db.protocols.where("protocolId").anyOf(protocolIdsToDelete).delete();
}

async function updateCategories(unProcessedCategories) {
  const categories = await fetchIconsForCategories(unProcessedCategories);
  const categoriesToDelete = categories.filter(categories => categories.deleted).map(category => category._id);

  await db.categories.bulkPut(categories);
  return await db.categories.where("_id").anyOf(categoriesToDelete).delete();
}

async function updateDB() {
  const lastUpdate = Number(new Date(localStorage.getItem("lastUpdateCheck")));

  const response = await fetch(`${process.env.REACT_APP_API_URL}/update?date=${lastUpdate}`, {
    credentials: 'include',
  });

  if (response.status === 401) {
    dbEvents.emit("not-authenticated");
    return "not-authenticated";
  } else if(response.status === 406) {
    dbEvents.emit("need-password-reset");
    return "need-password-reset"
  }

  const { protocols, categories } = await response.json();

  const updatePromises = [];
  if(categories.length > 0) {
    updatePromises.push(updateCategories(categories));
  }

  if(protocols.length > 0) {
    updatePromises.push(updateProtocols(protocols));
  }

  await Promise.all(updatePromises);
  if(updatePromises.length > 0) {
    localStorage.setItem("lastUpdateCheck", new Date());
    dbEvents.emit("updated");
    return "updated"
  } else {
    dbEvents.emit("not-updated");
    return "not-updated"
  }
}

async function connect() {
  console.log("Attempting to connect/re-connect to update socket...");
  const updateSocket = new WebSocket(process.env.REACT_APP_API_SOCKET_URL);
  updateSocket.addEventListener('open', async event => {
    console.log("Connected!");
    await updateDB();
  })

  updateSocket.addEventListener('message', async event => {
    if(event.data === "updated") {
      await updateDB();
      dbEvents.emit("updated");
    }
  });

  updateSocket.addEventListener('close', async event => {
    setTimeout(connect, 20000);
  });
}

export async function initializeDB() {
  const dbCounts = await Promise.all([
    db.protocols.count(),
    db.categories.count()
  ]);

  // If there are no records in the IDB, grab them all
  if(dbCounts.includes(0)) {
    try {
      await downloadAll();
      dbEvents.emit("initialized");
      return dbEvents.emit("updated");
    } catch(error) {
      console.error(error);
      return dbEvents.emit("not-updated");
    }
  }

  await connect();
  dbEvents.emit("initialized");
}

export const dbEvents = new EventEmitter();

export const dbActions = {
  updateDB,
  downloadAll,
  clearDB,
}
