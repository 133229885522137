import React from "react";
import DirectionStyleWrapper from "../UI/DirectionStyleWrapper";

function ProtocolEditorDirections() {
  return (
    <DirectionStyleWrapper>
      <h1>Category Editor</h1>
      <p>This is where you edit the selected category</p>
      <br></br>
      <br></br>

      <h2>Icon</h2>
      <p>Here you can select an existing icon, or upload a new one. These should be in jpeg, or png format.</p>
      <br></br>
      <br></br>

      <h2>Title</h2>
      <p>This is what will be displayed as the title of the category</p>
      <br></br>
      <br></br>

      <h2>Color</h2>
      <p>This is the color-bar to the left of the category, you can set this to white, or transparent, if you do not wish to display a color.</p>
      <br></br>
      <br></br>

      <h2>Slot</h2>
      <p>The slot is how the categories are ordered. This accepts decimals as well. 2.5 will be placed between slot 2 and 3, and you can have as many decimal places as you'd like. 3.14198 is valid as well. Slots start at the top, ascending.</p>
      <br></br>
      <br></br>

      <h2>Parent</h2>
      <p>If you wish to have a sub-category, you must first create a category of type "Category List". After doing so, you can select that category as the "parent".</p>
      <p>Setting a category as a parent, will allow you to hide multiple categories as sub-categories under a singular main category.</p>
      <p>You can have as many levels of sub-categories as you would like.</p>
      <br></br>
      <br></br>

      <h2>Type</h2>
      <p>This is how you select the type of category you want to create. Setting this to "Category List" allows you to assign sub-categories to this category.</p>
      <br></br>
      <br></br>

      <h2>Type Content</h2>
      <br></br>

      <h3>Id Prefix</h3>
      <p>This will be the prefix of the protocols that should be listed under this category. A prefix of "m" will match any protocols with this format of ID: "m-00", this is case-insensitive. A prefix of "p" will match "p-22" but not "ph-00".</p>
      <br></br>
      <br></br>

      <h3>Link To</h3>
      <p>This allows you to create "shortcuts" for specific protocols or other categories. If you would like to link to protocol "u-09", you would enter "/protocol/u-09" for the "Link To" field.</p>
      <br></br>
      <br></br>
    </DirectionStyleWrapper>
  )
}

export default ProtocolEditorDirections;