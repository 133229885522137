import styled from "styled-components"

const DirectionStyleWrapper = styled.div`
  h1 {
    font-size: 2rem;
    font-weight: bold;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: bold;
  }

  h3 {
    font-size: 1.3rem;
    font-weight: bold;
  }

  .code {
    padding: 0 10px;
    border: 2px solid black;
  }
`;

export default DirectionStyleWrapper;