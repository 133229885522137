function debounce(delay, callback) {
  let timerId;

  return function (event) {
    event.persist();
    if (timerId) clearTimeout(timerId);

    timerId = setTimeout(() => {
      callback(event);
      timerId = null;
    }, delay);
  }
}

export default debounce;
