import React from "react";
import DirectionStyleWrapper from "../UI/DirectionStyleWrapper";

function ProtocolEditorDirections() {
  return (
    <DirectionStyleWrapper>
      <h1>Documents Referencing</h1>
      <p>This is where you can find every document that references this document, this is useful if you want to delete a protocol. It ensures you never have a reference that points to nothing.</p>
      <p>You can click the protocol here and it will take you to the edit page for that referencing protocol</p>
    </DirectionStyleWrapper>
  )
}

export default ProtocolEditorDirections;