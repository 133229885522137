import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { useParams, useHistory, useLocation } from "react-router-dom";

import withToasts from "../components/HOCs/withToasts";

import AdminLayout from "../layouts/Admin"

import ProtocolDBHistory from "../components/protocol/ProtocolDBHistory";
import ProtocolEditor from "../components/protocol/ProtocolEditor";
import Protocol from "../components/protocol/Protocol";
import ReferencedInList from "../components/protocol/ReferencedInList";
import NotFound from "../components/NotFound";
import Header from "../components/Header";

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;

  >* {
    margin: 10px;
  }

  .protocol-editor {
    min-width: 400px;
    max-width: 850px;
    flex-grow: 2;
  }

  .protocol {
    overflow-y: auto;
    height: 775px;
    min-width: 400px;
    max-width: 600px;
    flex-grow: 2;
    padding: 10px;
    border: 1px solid black;
  }

  .references {
    overflow-y: auto;
    border: 1px solid black;
    min-width: 250px;
    flex-grow: 1;
  }

  .history {
    border: 1px solid black;
    min-width: 250px;
    flex-grow: 1;
  }
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function EditProtocol({toastError, toastSuccess, isNew, ...props}) {
  const params = useParams();
  const query = useQuery();
  const history = useHistory();
  const [originalProtocol, setOriginalProtocol] = useState(null);
  const [protocol, setProtocol] = useState(null);
  const [stagedFile, setStagedFile] = useState(null);
  const [referencingDocuments, setReferencingDocuments] = useState([]);

  useEffect(() => {
    if (query.get('status') === 'success') {
      toastSuccess('Protocol Updated');
    }
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function getReferencingDocuments() {
      if(!params.protocolId) return;

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/protocol/${params.protocolId}/referencing`, {
          credentials: 'include',
        });
        const documents = await response.json();
        setReferencingDocuments(documents);
      } catch (e) {
        console.log(e);
      }
    }

    getReferencingDocuments();
  }, [params.protocolId]);

  useEffect(() => {
    // API request for protocol
    async function fetchData() {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/protocol/${params.protocolId}`, {
        credentials: 'include'
      });
      if(response.status === 404) {
        setOriginalProtocol(null);
        setProtocol(null)
      } else {
        const protocol = await response.json();

        setOriginalProtocol(protocol);
        setProtocol(protocol);
      }
    }

    if(isNew) {
      setOriginalProtocol({});
      setProtocol({})
    } else {
      fetchData();
    }
  }, [params, isNew]);

  async function createProtocol(protocolData) {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/protocol`, {
        method: "POST",
        credentials: 'include',
        body: JSON.stringify(protocolData),
        headers: {
          "Content-Type": "application/json"
        } 
      });

      handleAPIResponse(response, `/protocol/${protocol.protocolId}/edit`);
    } catch(error) {
      toastError(error)
    }
  }

  async function updateProtocol(protocolData) {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/protocol/${protocolData._id}`, {
        method: "PUT",
        credentials: 'include',
        body: JSON.stringify(protocolData),
        headers: {
          "Content-Type": "application/json"
        } 
      });

      handleAPIResponse(response);
    } catch(error) {
      toastError(error)
    }
  }

  function computeFinalColorBars(content, currentColors) {
    const colorBarCount = (content.match(/---/g)||[]).length + 1;
    return currentColors.slice(0, colorBarCount);
  }

  function computeEditColorBars(content, currentColors) {
    const colorArray = currentColors ? currentColors : ["#FFFF00", "#0000FF", "#FF0000"];

    const colorBarCount = (content.match(/---/g)||[]).length;
    const currentColorAmount = colorArray.length;

    const missingColors = colorBarCount - currentColorAmount + 1;
    const colorAdditions = missingColors < 0 ? 0 : missingColors;

    return [...colorArray, ...(new Array(colorAdditions).fill('#D3D3D3'))];
  }

  function handleOnChange(event) {
    const propertyName = event.target.name;

    if(propertyName === "contentType") {
      setProtocol({...protocol, [propertyName]: event.target.value, content: ""});
      setStagedFile(null);
    } else if(propertyName === "content") {
      const editedProtocolData = {
        ...protocol,
        [propertyName]: event.target.value,
        colorBars: computeEditColorBars(event.target.value, protocol.colorBars)
      };

      setProtocol(editedProtocolData);
    } else {
      const editedProtocolData = {
        ...protocol,
        [propertyName]: event.target.value,
      };

      setProtocol(editedProtocolData);
    }
  }

  async function uploadFile() {
    const formData = new FormData();
    formData.append('icon', stagedFile);

    const response = await fetch(`${process.env.REACT_APP_API_URL}/protocol-file`, {
      method: "POST",
      credentials: 'include',
      body: formData
    });

    if(response.status > 299) {
      const message = await response.text();
      throw new Error(message);
    }

    const file = await response.json();
    return file.s3Path
  }

  async function handleSave() {
    if(stagedFile && protocol.contentType !== "markdown") {
      // Change the content to the s3Path for storage purposes in the local DB
      try {
        const s3Path = await uploadFile();
        return isNew ? createProtocol({...protocol, content: s3Path}) : updateProtocol({...protocol, content: s3Path})
      } catch(error) {
        toastError(error)
      }
    } else {
      const protocolData = {...protocol, colorBars: computeFinalColorBars(protocol.content, protocol.colorBars)};
      return isNew ? createProtocol(protocolData) : updateProtocol(protocolData) 
    }
  }

  async function handleDelete() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/protocol/${protocol._id}`, {
        credentials: 'include',
        method: "DELETE",
      });

      handleAPIResponse(response, "/");
    } catch(error) {
      toastError(error);
    }
  }

  function handleFileLoad(event) {
    // Staging the file so we don't have to upload unless save is clicked
    const file = event.target.files[0];
    setStagedFile(file);

    // Set the content to the file to mimic how it would be loaded from the IDB
    setProtocol({...protocol, content: file});
  }

  function handleReset() {
    setProtocol(originalProtocol);
  }

  async function applyHistory(historicalItem) {
    return updateProtocol(historicalItem);
  }

  async function handleAPIResponse(response, redirectTo) {
    if(response.status > 299) {
      // TODO: Make helper with good error codes
      const apiErr = new Error(await response.text())
      toastError(apiErr);
    } else if (redirectTo) {
      history.push(redirectTo);
    } else {
      history.push('?status=success');
      window.location.reload();
    }
  }

  if(protocol) {
    return (
      <AdminLayout>
        <Container>
          <div className="protocol-editor">
            <ProtocolEditor 
              protocol={ {...protocol, colorBars: protocol.colorBars || ["#FFFF00", "#0000FF", "#FF0000"]} } // This is the default in the protocolModel as well
              protocolLink={ !isNew && `/protocol/${protocol.protocolId}` }
              onChange={ handleOnChange }
              onFileLoad={ handleFileLoad }
              onSave={ handleSave }
              onReset={ handleReset }
              onDelete={ handleDelete }
              ifReferenced={ referencingDocuments.length > 0 }
            />
          </div>

          <div className="protocol">
            <Header title={protocol.title} id={protocol.protocolId}/>
            <Protocol protocol={ protocol } />
          </div>

          <div className="references">
            { !isNew && originalProtocol && <ReferencedInList referencingDocuments={ referencingDocuments } /> }
          </div>
          
          <div className="history">
            { !isNew && originalProtocol && <ProtocolDBHistory protocol={ originalProtocol } onApplyHistory={ applyHistory } /> }
          </div>
        </Container>
      </AdminLayout>
    )
  } else {
    return <NotFound message="Protocol not found" />;
  }
}

export default withToasts(EditProtocol);
