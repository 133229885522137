import React, { useState, useEffect } from "react";

import { db } from "../db";

import StandardLayout from "../layouts/Standard";
import CategoryList from "../components/category/CategoryList";

export default function Index() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    async function getCategories() {
      const categories = await db.categories.orderBy('slot').filter(category => !category.parent).toArray();
      setCategories(categories);
    }


    getCategories();
  }, []);

  return (
    <StandardLayout title="Categories" adminLink="/editCategories">
      <CategoryList categories={categories} />
    </StandardLayout>
  );
}
