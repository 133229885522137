import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Category from "./Category";
import CategoryLinkTypeEditorAdapter from "./CategoryLinkTypeEditorAdapter";
import IconSelector from "./IconSelector";
import AdminDirectionButton from "../UI/AdminDirectionButton";
import CategoryEditorDirections from "./CategoryAdminDirections";

const CategoryEditorContainer = styled.fieldset`
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon-group {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const CategoryForm = styled.form`
  width: 90%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  border: 1px solid black;

  .label-input {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;

    margin-bottom: 40px;
  }
  .label-input > label {
    width: 15%;
    float: left;
  }


 fieldset {
   display: flex;
   flex-direction: column;
 }

  .delete-btn {
    margin-bottom: 20px;
  }

  .card {
    margin-top: 30px;
    margin-left: -20px;
  }

  label {
    font-weight: bold;
    margin-bottom: 10px;
  }

  input, select, button {
    width: 150px;
  }

  input[type=submit] {
    margin: 20px 0;
    align-self: center;
  }

  .admin-directions {
    align-self: flex-end;
    padding-bottom: 20px;
  }

  .admin-directions button {
    width: 30px;
  }
`;

export default function CategoryEditor(props) {
  const [category, setCategory] = useState(props.category);

  // Update when props update
  useEffect(() => {
    setCategory(props.category);
  }, [props.category])

  function onDelete(event) {
    event.preventDefault();
    props.onDelete(event);
  }

  function onSubmit(event) {
    event.preventDefault();
    props.onSubmit(category);
  }

  function isValidParent(category, relatableCategory) {
    return (
      !relatableCategory.deleted && 
      relatableCategory._id !== category._id && 
      relatableCategory.parent !== category._id &&
      relatableCategory.linkType === 'category_list'
    );
  }

  function onAdapterChange(categoryData) {
    setCategory({...category, ...categoryData});
  }

  function handleOnChange(propertyName) {
    return function(event) {
      props.onEdit();
      setCategory({
        ...category,
        [propertyName]: event.target.value || null,
      })
    }
  }

  function calculateSlotNumber(category) {
    if(category.slot) {
      return category.slot;
    } else if(props.categoryList && props.categoryList.length > 0) {
      const slotNumbers = props.categoryList.map(category => category.slot);
      return Math.max(...slotNumbers) + 1;
    } else {
      return 0;
    }
  }

  return (
    <CategoryEditorContainer disabled={props.readOnly}>
      <CategoryForm onSubmit={onSubmit}>
        <div className="card">
          <Category
            category={category}
            icon={category.icon && `${process.env.REACT_APP_S3_BUCKET_URL}/${category.icon}`}
          />
        </div>

        {!props.readOnly && !props.isNewCategory && <button className="delete-btn" onClick={onDelete}>{
          category.deleted ? "Restore" : "Delete"
        }</button>}

        <fieldset disabled={category.deleted}>

          <div className="label-input">
            <label htmlFor="icon">Icon:</label>
            <IconSelector 
              name="icon"
              value={category.icon} 
              onChange={handleOnChange('icon')} 
            />
          </div>

          <div className="label-input">
            <label htmlFor="title">
              Title: 
            </label>
            <input
              name="title"
              value={category.title}
              onChange={handleOnChange('title')}
              maxLength="25"
              required
            />
          </div>

          <div className="label-input">
            <label htmlFor="color">
              Color:
            </label>
            <input 
              type="color"
              value={category.color}
              onChange={handleOnChange('color')}
            />
          </div>

          <div className="label-input">
            <label htmlFor="slot">
              Slot:
            </label>
            <input 
              type="number" 
              name="slot" 
              value={ calculateSlotNumber(category) } 
              onChange={handleOnChange('slot')} 
              required
            />
          </div>    

          <div className="label-input">
            <label htmlFor="parent">
              Parent:
            </label>
            <select name="parent" value={category.parent || ''} onChange={handleOnChange('parent')}>
              <option value={''}>None</option>
              { props.categoryList && props.categoryList.map(relatableCategory => {
                if(isValidParent(category, relatableCategory)) { 
                  return(
                    <option 
                      key={relatableCategory._id} 
                      value={relatableCategory._id}>
                        {relatableCategory.title}
                    </option>
                  );
                } else {
                  return null;
                }
              }) }
            </select>
          </div>

          <div className="label-input">
            <label htmlFor="type">
              Type:
            </label>
            <select name="type" value={category.linkType} onChange={handleOnChange('linkType')}>
              <option value="protocol_list">Protocol List</option>
              <option value="link">Link</option>
              <option value="category_list">Category list</option>
            </select>
          </div>

          <CategoryLinkTypeEditorAdapter 
            category={category}
            onChange={onAdapterChange}
          />

        { !props.readOnly && <input type="submit" value="Submit"/> }
        </fieldset>
        <div className="admin-directions">
          <AdminDirectionButton><CategoryEditorDirections /></AdminDirectionButton>
        </div>
      </CategoryForm>
    </CategoryEditorContainer>
  );
}
