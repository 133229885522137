import React from "react";

import TableStyling from "./TableStyling";
import withToasts from "../HOCs/withToasts";

function UserEditTable({toastError, ...props}) {
  const {
    users,
    onEnableUser,
    onDisableUser,
    onChangeRole,
    onResetPassword
  } = props;

  function verifyPermissions(userToModify, callback) {
    return (event) => {
      if(userToModify.roleLevel > props.user.roleLevel) {
        toastError(new Error("You cannot edit users above your role"))
      } else {
        callback(event);
      }
    }
  }

  return (
    <TableStyling>
      <thead>
        <tr>
          <th>Username</th>
          <th>Disabled</th>
          <th>User</th>
          <th>Admin</th>
          <th>Super User</th>
          <th>Reset Password</th>
        </tr>
      </thead>
      <tbody>
      { users.map(user => (
        <tr key={user.username}>
          <td className="truncate">{ user.username }</td>

          <td>
            <input 
              type="checkbox" 
              checked={user.roles.includes('disabled')}
              onChange={verifyPermissions(user, () => user.roles.includes('disabled') ? onEnableUser(user.username) : onDisableUser(user.username))}
            />
          </td>

          <td>
            <input 
              type="radio" 
              name={user.username}
              disabled={user.roles.includes('disabled')}
              checked={user.roleLevel === 1}
              onChange={verifyPermissions(user, onChangeRole(user, 1))}
            />
          </td>

          <td>
            <input 
              type="radio" 
              name={user.username}
              disabled={user.roles.includes('disabled')}
              checked={user.roleLevel === 2}
              onChange={verifyPermissions(user, onChangeRole(user, 2))}
            />
          </td>

          <td>
            <input 
              type="radio" 
              name={user.username}
              disabled={user.roles.includes('disabled')}
              checked={user.roleLevel === 3}
              onChange={verifyPermissions(user, onChangeRole(user, 3))}
            />
          </td>

          <td>
            <button 
              onClick={ verifyPermissions(user, onResetPassword) }
              value={ user.username }
            >Reset Password</button>
          </td>
        </tr>
        ))
      }
      </tbody>
    </TableStyling>
  );
}

export default withToasts(UserEditTable);