import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import "./styles/reset.css";
import "./styles/global-style.css";
import App from './App';
import * as serviceWorker from './serviceWorker';

// Initialize DB
import { initializeDB } from "./db";
const user = localStorage.getItem("user"); 
if(user) {
  initializeDB();
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
