import React from "react";

export default function FileProtocolEditor(props) {
  const { onFileLoad, accept } = props;
  return (
    <div>
      <input 
        type="file" 
        name="file" 
        accept={ accept }
        onChange={ onFileLoad }
      />
    </div>
  )
}