import React from "react";

import MDProtocol from "./MDProtocol";
import ImageProtocol from "./ImageProtocol";
import PDFProtocol from "./PDFProtocol";

function ProtocolAdapter(props) {
  const { contentType } = props.protocol;
  switch(contentType || "markdown") {
    case "markdown":
      return <MDProtocol {...props} />
    case "image":
      return <ImageProtocol {...props} />
    case "pdf":
      return <PDFProtocol {...props} />
    default:
      return <p>Content Type: { contentType }</p>
  }
}

export default function Protocol(props) {
  const { protocol } = props;
  return (
    <div className="protocol-body">
      <ProtocolAdapter protocol={ protocol }/>
    </div>
  )
}