import React, { useState, useEffect, useCallback } from 'react';
import { dbActions, dbEvents, initializeDB } from "../db";

export const UserContext = React.createContext();

export function withUserContextProvider(Component) {
  return function UserContextProvider(props) {
    const storedUser = JSON.parse(window.localStorage.getItem('user'));
    const lastLogin = window.localStorage.getItem('lastLogin');
    const [user, setUser] = useState(storedUser || null);
    const [lastLoginDate, setLastLoginDate] = useState(lastLogin ? new Date(lastLogin) : null);
    const memoizedLogoutUser = useCallback(logoutUser, [user]);

    useEffect(() => {  
      function dbNotAuthenticatedHandler() {
        memoizedLogoutUser();
      }

      dbEvents.on("not-authenticated", dbNotAuthenticatedHandler);
  
      return () => dbEvents.off("not-authenticated", dbNotAuthenticatedHandler);
    }, [memoizedLogoutUser])

    useEffect(() => {
      function expirationTimer() {
        if (!user || user.loggedOut) {
          memoizedLogoutUser();
        } else if (user) {
          const currentDate = new Date();
          const expirationDate = new Date(user.expires);
          const expired = currentDate > expirationDate;
          if (expired) memoizedLogoutUser();
        }

        if (lastLoginDate) {
          // Clone date to prevent mutations.
          const weekSinceLastLogin = new Date(lastLoginDate.getTime());
          weekSinceLastLogin.setDate(
            weekSinceLastLogin.getDate() + Number(process.env.REACT_APP_DELETE_DATA_INTERVAL)
          );

          if (weekSinceLastLogin < new Date()) {
            dbActions.clearDB();

            if (user) memoizedLogoutUser();
          }
        }
      }

      expirationTimer();
      const timer = window.setInterval(expirationTimer, 60000);

      return () => {
        clearInterval(timer);
      }
    }, [user, lastLoginDate, memoizedLogoutUser])

    // Do this if lastLoginDate is updated.
    useEffect(() => {
      if (lastLoginDate) {
        window.localStorage.setItem('lastLogin', lastLoginDate);
      }
    }, [lastLoginDate])

    // Do this if user is updated.
    useEffect(() => {
      try {
        window.localStorage.setItem('user', JSON.stringify(user));
      } catch {
        window.localStorage.setItem('user', 'null');
      }
    }, [user])

    function onLogin(user) {
      if (user.roleLevel >= 2) {
        user.isAdmin = true;
      }
      setUser(user);
      setLastLoginDate(new Date());
      initializeDB();
    }

    const attemptLogin = async (username, password) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            username,
            password
          }),
        });

        // Create clone to preserve string for consumption on caller.
        const responseClone = response.clone();

        const loginData = await response.json();
        if (response.ok) onLogin(loginData);
      
        return responseClone;
      } catch(error) {
        throw error;
      }
    };

    async function logoutUser() {
      try {
        await fetch(`${process.env.REACT_APP_API_URL}/auth/logout`, { 
          credentials: 'include'
        });
        setUser(null);
      } catch(error) {
        if (user && !user.loggedOut) {
          setUser({ loggedOut: true });
        }
      }
    }

    const attemptRegistration = async (username, password, signupToken) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/register`, {
          method: "POST",
          credentials: 'include',
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ 
            username,
            password,
            signupToken
          })
        });
        const {status} = response;
        if (status === 200) attemptLogin(username, password);
        return response;
      } catch (error) {
        throw error;
      }
    };

    const attemptPasswordChange = async (password) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/change-password/${user.username}`, {
          method: "PUT",
          credentials: "include",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ password }),
        });

        if(!response.ok) {
          return response;
        }

        return attemptLogin(user.username, password);
      } catch(error) {
        throw error;
      }
    };

    // TODO Use Proper reducer/action pattern
    const userActions = {
      LOGIN: attemptLogin,
      LOGOUT: memoizedLogoutUser,
      REGISTER: attemptRegistration,
      CHANGE_PASSWORD: attemptPasswordChange,
    }
  
    return (
      <UserContext.Provider value={{user, userActions}}>
        <Component {...props} />
      </UserContext.Provider>
    )
  }
}
