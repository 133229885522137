import React, { useState, useContext } from 'react';

import { UserContext } from "../contexts/UserContext";
import withToasts from "../components/HOCs/withToasts";

function ChangePassword({ toastError, toastSuccess, ...props }) {
  const { userActions } = useContext(UserContext);
  const [formInput, setFormInput] = useState({ password: '' })

  async function formSubmitHandler(event) {
    event.preventDefault();
    try {
      const passwordChangeResponse = await userActions.CHANGE_PASSWORD(formInput.password);
      if (!passwordChangeResponse.ok) {
        const message = await passwordChangeResponse.json();
        toastError(new Error(message)); 
      }
    } catch(error) {
      // TODO Text Configuration file.
      toastError(new Error("Network Failure. Please try when you have an active internet connection."));
    }
  }

  function handleOnChange(propertyName) {
    return function(event) {
      setFormInput({
        ...formInput,
        [propertyName]: event.target.value,
      })
    }
  }

  return (
    <form className="form" onSubmit={formSubmitHandler}>
      <label htmlFor="newpassword">New Password:</label>
      <input
        type="password"
        onChange={handleOnChange('password')}
        minLength={process.env.REACT_APP_MIN_PASSWORD_LENGTH}
      ></input>
      <input
        type="submit"
        value="Submit"
      ></input>
    </form>
  )
}

export default withToasts(ChangePassword);
