import React, { useContext } from "react";
import Styled from "styled-components";
import Header from "../components/Header";
import { Link } from "react-router-dom";

import { SettingsContext } from "../contexts/SettingsContext";

const PageWrapperStyle = Styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  /* To always allow the touch menu to not cover text */
  margin-bottom: 100px;
`

function PageWrapper(props) {
  const { settings } = useContext(SettingsContext);

  return (
    <PageWrapperStyle>
      { settings.adminMode && props.adminLink && 
        <Link to={props.adminLink}>
          <button>Admin View</button>
        </Link>
      }
      <Header title={props.title} id={props.id} img={props.img} color={props.color}/>
      {props.children}
    </PageWrapperStyle>
  )
}

export default PageWrapper;