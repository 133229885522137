import React from "react";
import styled from "styled-components";

const PDFViewer = styled.embed`
  width: 100%;
  height: 80vh;
`;

export default function PDFProtocol(props) {
  const { content } = props.protocol;
  let src = '';

  if(content.arrayBuffer) {
    const PDFBlob = new Blob([content.arrayBuffer], {type: content.type});
    src = URL.createObjectURL(PDFBlob);
  } else {
    src = `${process.env.REACT_APP_S3_BUCKET_URL}/${content}`;
  }

  return (
    content ? <PDFViewer src={ src } /> : <p>Upload PDF file for preview</p>
  )
}