import React from "react";

export default function HistoryItem(props) {
  if(!props.history) return null;
  const { history, isCreationEntry } = props;

  return (
    <tr>
      <td>{(new Date(history.modifiedDate)).toLocaleDateString()}</td>
      <td>{history.modifiedBy}</td>
      <td>
        { isCreationEntry ? 
          <p>created</p> :
          <button onClick={props.onPreview}>Preview</button>
        }
      </td>
    </tr>
  )
}