import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { useParams } from 'react-router-dom';
import styled from "styled-components";

import { db } from "../db";
import StandardLayout from "../layouts/Standard";
import ProtocolAdapter from "../components/protocol/Protocol";
import NotFound from "../components/NotFound";

const ProtocolAdapterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .internal-wrapper {
    width: 98%;
  }
`;

function Protocol() {
  const params = useParams();
  const location = useLocation();
  const [protocol, setProtocol] = useState(null);
  const [categoryColor, setCategoryColor] = useState(null);

  useEffect(() => {
    async function getDataFromDB() {
      const protocol = await db.protocols.where("protocolId").equalsIgnoreCase(params.protocolId).first();
      if(!protocol) {
        setProtocol(null);
      } else {
        setProtocol({...protocol, content: protocol.content});
      }
    }

    getDataFromDB();
  }, [params]);

  useEffect(() => {
    async function getCategoryColor() {
      if (protocol) {
        const protocolIdPrefix = protocol.protocolId.split("-")[0]; // Split "ph-00" to "ph"

        const category = await db.categories
          .where('linkData')
          .equalsIgnoreCase(protocolIdPrefix)
          .first();

        if(category) setCategoryColor(category.color);
      }
    }

    getCategoryColor();
  }, [protocol]);

  if(protocol) {
    return (
      <StandardLayout
        title={protocol.title}
        id={protocol.protocolId} 
        adminLink={`${location.pathname}/edit`}
        color={categoryColor}  
      >
        <ProtocolAdapterWrapper>
          <div className="internal-wrapper">
            <ProtocolAdapter protocol={ protocol } />
          </div>
        </ProtocolAdapterWrapper>
      </StandardLayout>
    );
  } else {
    return <NotFound message="Protocol not found" />
  }
} 

export default Protocol;
