import React from "react";
import Styled from "styled-components";

const PageWrapperStyle = Styled.div`
  /* To always allow the touch menu to not cover text */
  margin-bottom: 100px;
`

function PageWrapper(props) {
  return (
    <PageWrapperStyle>
      {props.children}
    </PageWrapperStyle>
  )
}

export default PageWrapper;