import React, { useEffect, useState } from "react";
import styled from "styled-components";

import HistoryList from "../history/HistoryList";
import CategoryEditor from "./CategoryEditor";

const HistoryListWrapper = styled.div`
  border: 1px solid black;
`;

export default function CategoryDBHistory(props) {
  const [categoryHistory, setCategoryHistory] = useState([]);

  useEffect(() => {
    async function getCategoryHistory() {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/category/${props.category._id}?history=true`,
         {credentials: 'include'}
      );

      const { history } = await response.json();
      setCategoryHistory(history.reverse());
    }
    getCategoryHistory();
  }, [props.category._id]);

  function renderCategory(category) {
    return <CategoryEditor category={category} readOnly />
  }

  function applyHistory(historicalData) {
    // We need to overwrite the existing _id as the on in the history is 
    // actually the _id of the history subdoc
    const updatedCategory = {...historicalData, _id: props.category._id}
    props.onApplyHistory(updatedCategory);
  }

  return (
  <HistoryListWrapper>
    <HistoryList 
      history={categoryHistory} 
      preview={renderCategory} 
      onApplyHistory={applyHistory}
    />
  </HistoryListWrapper>
  );
}
