function titleMarkdownSection(sectionMarkdown) {
  const title = sectionMarkdown.match(/^.*(?=(\n))/g);

  return {
    title: title,
    markdown: title ? sectionMarkdown.replace(title, '') : sectionMarkdown
  }
}

function formatMarkdownLinks(section) {
  const mdLinkMatches = section.match(/<([^ .>]+)>/g);
  if (mdLinkMatches) {
    let formattedMD = section;
    for (const mdLink of mdLinkMatches) {
      const linkName = mdLink.replace(/<|>/g, "");
      formattedMD = formattedMD.replace(mdLink, `[${linkName}](/protocol/${linkName})`);
    }

    return formattedMD;
  } else {
    return section;
  }
}

function handleTitlelessMarkdownSection(mdSection){
  return {
    title: undefined,
    markdown: formatMarkdownLinks(mdSection)
  }
}

function handleTitledMarkdownSection(mdSection) {
  return titleMarkdownSection(formatMarkdownLinks(mdSection));
}

function preProcessMarkdown(rawMarkdown) {
  const markdownSections = rawMarkdown.split('## ');
  const preProcessedMarkdownSections = markdownSections.map((section, index) => {
    /**
     * If the first item in the array is empty, we can assume that every section has a title. 
     * The first item is `""` during a split, if the first part of the string matches
     * e.g.
     * 
     *   "## Hello World... ## Second".split("## ");
     *   => ["", "Hello World...", "Second"]
     */ 
    if(!section) return null;

    if (index === 0) {
      return handleTitlelessMarkdownSection(section);
    } else {
      return handleTitledMarkdownSection(section);
    }
  });

  return preProcessedMarkdownSections;
}

export default preProcessMarkdown;
