import React, { useEffect, useState } from "react";

import HistoryList from "../history/HistoryList";
import Protocol from "./Protocol";

export default function CategoryDBHistory(props) {
  const [protocolHistory, setProtocolHistory] = useState([]);

  useEffect(() => {
    async function getProtocolHistory() {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/protocol/${props.protocol.protocolId}?history=true`,
         {credentials: 'include'}
      );

      const { history } = await response.json();
      setProtocolHistory(history.reverse());
    }
    getProtocolHistory();
  }, [props.protocol.protocolId]);

  function renderCategory(protocol) {
    return <Protocol protocol={ protocol } />
  }

  function applyHistory(historicalData) {
    props.onApplyHistory({...historicalData, _id: props.protocol._id});
  }

  return (
    <HistoryList 
      history={ protocolHistory } 
      preview={ renderCategory } 
      onApplyHistory={ applyHistory }
    />
  );
}
