import React from "react";
import DirectionStyleWrapper from "../UI/DirectionStyleWrapper";

function ProtocolEditorDirections() {
  return (
    <DirectionStyleWrapper>
      <h1>History</h1>
      <p>This is where you can view and apply the history of an individual item. This tells you who made the change and on what day. The preview button lets you view what the protocol looked like before their edits.</p>
      <p>If you open the preview, it allows you to "apply" that version of the item, or cancel to close the historical item.</p>
    </DirectionStyleWrapper>
  )
}

export default ProtocolEditorDirections;
